import translations_en from "../i18n/en.json";
import translations_de from "../i18n/de.json";
import translations_es from "../i18n/es.json";
import translations_fr from "../i18n/fr.json";
import translations_it from "../i18n/it.json";
import translations_pt from "../i18n/pt.json";
import translations_ru from "../i18n/ru.json";
import translations_uk from "../i18n/uk.json";

export const txLanguages = [
  'en-us',
  'fr-fr',
  'de-de',
  'it-it',
  'pt-pt',
  'ru',
  'es-es',
  'uk'
];

export const blogLanguages = [
  'en-us',
  'pt-pt',
  'es-es'
];

export function txString(string, lang, debug) {
  let source;
  let result = '';

  if (string && typeof string === 'string') {
    if (lang === 'en-us') source = translations_en;
    else if (lang === 'de-de') source = translations_de;
    else if (lang === 'es-es') source = translations_es;
    else if (lang === 'fr-fr') source = translations_fr;
    else if (lang === 'it-it') source = translations_it;
    else if (lang === 'pt-pt') source = translations_pt;
    else if (lang === 'ru') source = translations_ru;
    else if (lang === 'uk') source = translations_uk;

    if (debug) {
      console.log('txString before: ' + string);
    }

    string = string
      .replace(/ <br \/>/g, '<br>')
      .replace(/\n/g, '<br>')
      .replace(/ <br>/g, '<br>')
      .replace(/<br \/>/g, '<br>');

    if (
      string.indexOf('& ') !== -1 &&
      string.indexOf('&amp; ') === -1 &&
      source[string.replace(/& /g, '&amp; ')]
    ) {
      string = string.replace(/& /g, '&amp; ');
    }

    if (
      string.indexOf('&amp; ') !== -1 &&
      string.indexOf('& ') === -1 &&
      source[string.replace(/&amp; /g, '& ')]
    ) {
      string = string.replace(/&amp; /g, '& ');
    }

    if (debug) console.log(string);

    if (source[string]) {
      result = source[string];
      if (debug) console.log('String exist in source');
    } else {
      if (debug) console.log("String doesn't exist in source");
      if (source[string.replace(/'/g, "’")]) {
        result = source[string.replace(/'/g, "’")];
      } else if (source[string.replace(/’/g, "'")]) {
        result = source[string.replace(/’/g, "'")];
      } else if (source[string.replace(/<br>/g, ' <br>')]) {
        result = source[string.replace(/<br>/g, ' <br>')];
      } else if (source[string.replace(/&#39;/g, "'")]) {
        result = source[string.replace(/&#39;/g, "'")];
      } else if (source[string.replace(/&quot;/g, '"')]) {
        result = source[string.replace(/&quot;/g, '"')];
      } else if (source[string.replace(/&gt;/g, '>')]) {
        result = source[string.replace(/&gt;/g, '>')];
      } else if (source[string.replace(/ /g, '&nbsp;')]) {
        result = source[string.replace(/ /g, '&nbsp;')];
      } else if (translations_en[string]) {
        result = translations_en[string];
      } else if (source[string.replace(/\\"/g, '"')]) {
        result = source[string.replace(/\\"/g, '"')];
      } else if (source[string.replace(/ \n/g, ' ')]) {
        result = source[string.replace(/ \n/g, ' ')];
      }
    }

    if (debug) {
      console.log('txString after: ' + result);
    }
  } else {
    // console.warn('*******');
    // console.log(typeof string);
    // console.log(string);
  }

  return result;
};

export function txLink(link, lang) {
  if (link.includes('https://')) {
    if (
      (
      link.includes('px-dev') && link.includes('frontend')
      ) ||
      (
        link.includes('//pixellu') || link.includes('//www.pixellu')
      )
    ) {
      let url = new URL(link);
      return txLink(url.pathname, lang);
    } else {
      return link;
    }
  } else {
    if (lang === 'en-us') {
      return link;
    } else {
      return '/' + lang + link;
    }
  }
}

export function txBlogLink(link, lang) {
  if (lang === 'pt-pt' || lang === 'es-es')
    return '/' + lang + link;
  else
    return link;
}

export function txVideoLink(link, lang) {
  if (
    lang === 'pt-pt' ||
    lang === 'es-es' ||
    lang === 'ru'
  )
    return '/' + lang + link;
  else
    return link;
}

export function txHTML(data, lang, debug) {
  let resultHTML = '';

  data = data.substring(1, data.length - 1);

  if (debug) {
    console.log(data);
  }

  let dataArray = data.split(/></g);
  for (let i = 0; i < dataArray.length; i++){
    dataArray[i] = '<' + dataArray[i] + '>';
  }

  for (let i in dataArray) {
    if (dataArray[i].indexOf('</') !== -1 && dataArray[i].slice(0, 2) !== '</') {
      resultHTML +=
        dataArray[i].slice(0, dataArray[i].indexOf('>') + 1) +
          txString(dataArray[i].slice(dataArray[i].indexOf('>') + 1, dataArray[i].indexOf('</')), lang, debug) +
        dataArray[i].slice(dataArray[i].indexOf('</'), dataArray[i].length);
    } else if (dataArray[i].indexOf('</') === -1) {
      resultHTML += dataArray[i].slice(0, dataArray[i].indexOf('>') + 1);
    } else if (dataArray[i].slice(0, 2) === '</') {
      resultHTML += dataArray[i];
    }
  }

  if (debug) {
    console.warn(resultHTML);
  }

  return resultHTML;
}

export function txHTMLWithMarkers(data, lang, debug) {
  let result = '';

  if (data.includes('The following languages are currently supported in SmartAlbums:')) {
    data = 'The following languages are currently supported in SmartAlbums: <p>English<br>Portuguese<br>Spanish<br>Russian<br>Ukrainian<br>Italian<br>German<br>French</p><p><a target="_blank" rel="noopener" href="https://help.pixellu.com/article/756-multi-language-support">Click here</a> to learn more about language support.</p>';
  }

  if (data.includes('The following languages are currently supported in the Client Area of Galleries:')) {
    data = 'The following languages are currently supported in the Client Area of Galleries:<p>English<br>Portuguese<br>Spanish<br>Russian<br>Ukrainian<br>Italian<br>German<br>French</p><p>The Photographer Area is currently supported in English. <a target="_blank" rel="noopener" href="https://help.pixellu.com/article/1006-supported-languages-galleries">Learn More &gt;</a></p>';
  }

  if (data.includes('The following languages are currently supported in SmartSlides:')) {
    data = 'The following languages are currently supported in SmartSlides:<p>English<br>Portuguese<br>Spanish<br>Russian<br>Ukrainian<br>Italian<br>German</p><p><a target="_blank" rel="noopener" href="https://help.pixellu.com/article/948-supported-languages">Click here</a> to learn more about language support.</p>';
  }

  if (data.slice(0, 3) === '<p>' && data.slice(data.length - 4, data.length)) {
    result = data.replace('<p>', '').replace('</p>', '');
  } else {
    result = data;
  }

  let count = (data.match(/href="/g) || []).length;
  let linksArray = [];

  if (result.includes('href=')) {
    for (let i = 0; i < count; i++) {
      result = result.replace(/href="(.*?)"/, function(a, b) {
        linksArray.push(b);
        return 'href=\\"{{' + i + '}}\\"';
      });
    }
  }

  if (result.includes('target=')) {
    for (let i = 0; i < count; i++) {
      result = result.replace(/target="(.*?)"/, function(a, b) {
        return 'target=\\"' + b + '\\"';
      });
    }
  }

  result = result.replace(/\\"/g, '"').replace(/ {2}/g, ' ');

  result = result.replace(/ rel="noopener noreferrer"/g, '');

  if (debug) console.log(result);

  if (data.slice(0, 3) === '<p>' && data.slice(data.length - 4, data.length)) {
    result = '<p>' + txString(result, lang) + '</p>';
  } else {
    result = txString(result, lang);
  }

  for (let i in linksArray) {
    result = result.replace('{{' + i + '}}', txLink(linksArray[i], lang));
  }

  if (debug) console.log(result);

  return result;
}

export function txEmbed(data, key, lang) {
  let result = '';
  let url = '';

  for (let i in data) {
    if (i === key) {
      if (lang === 'en-us') {
        result = i;
      } else {
        result = i + '_' + lang.slice(0, 2);
      }
    }
  }

  url = data[result].embed_url;

  return url;
}

export function txAsset(data, key, lang) {
  let result = '';
  let url = '';

  for (let i in data) {
    if (i === key) {
      if (lang === 'en-us') {
        result = i;
      } else {
        result = i + '_' + lang.slice(0, 2);
      }
    }
  }

  url = data[result].url;

  return url;
}

export function txSet(data, key, lang) {
  let result = '';
  let result_key = '';

  for (let i in data) {
    if (i === key) {
      if (lang === 'en-us') {
        result = i;
      } else {
        result = i + '_' + lang.slice(0, 2);
      }
    }
  }

  result_key = data[result];

  return result_key;
}
