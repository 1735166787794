import { txLanguages } from "./translations-helper";

/**
 * Sets "active" class to the gatasby Link in case it matces with current page link
 *
 * @param isPartiallyCurrent - React Link parameter
 * @returns { className: "active" } object
 */
export function isActiveLink ({ href, location:{pathname} }) {
  return (href.replace(/^\/|\/$/g, '') === pathname.replace(/^\/|\/$/g, '')) ? { className: "active" } : null;
};

/**
 * Sets "data-active" parameter to "active" to the gatasby Link in case it matces with current page link
 *
 * @param isPartiallyCurrent - React Link parameter
 * @returns { className: "active" } object
 */
export function isActiveLinkDataParamenter ({ href, location:{pathname} }) {
  return (href.replace(/^\/|\/$/g, '') === pathname.replace(/^\/|\/$/g, '')) ? { "data-active": "active" } : null;
};

/**
 * Sets "active" class to the gatsby Link in case it partially matces with current page link
 *
 * @param isPartiallyCurrent - React Link parameter
 * @returns { className: "active" } object
 */
export function isPartiallyActiveLink ({ href, isPartiallyCurrent, location: {pathname} }) {
  let lang_sub_folder = pathname.split('/');
  lang_sub_folder = lang_sub_folder[1];

  if (txLanguages.includes(lang_sub_folder)) {
    const isPartiallyCurrentWithLang = pathname.includes(href) || pathname.includes('/' + lang_sub_folder + href.slice(0, -1));

    return isPartiallyCurrentWithLang ? { className: 'active' } : { className: 'not-active' };
  } else {
    return isPartiallyCurrent ? { className: 'active' } : null;
  }
};

/**
 * Inserts links in place of template variables depending on current environment set.
 *
 * @param text - text with template variables
 * @param envLinks - array of links to be used for current environment
 * @return text with links
 */
export function InsertEnvDependentLinks(text, envLinks)
{
  let linkTemplate, trailingSlash, envLink;

  for (linkTemplate in envLinks) {
    envLink = envLinks[linkTemplate];
    trailingSlash = ('/' === envLink[envLink.length - 1]) ? '/?' : '';

    text = text.replace(new RegExp("(http(:?s)?://)?(?:{{|%7B%7B)"+ linkTemplate +"(?:}}|%7D%7D)"+ trailingSlash, "g"), envLinks[linkTemplate]);
  }

  return text
}

/**
 * Returns text of the URI segment
 *
 * @param {int} index - index of the URI segment
 * @param {Object} location - Gatsby location object
 * @returns String or null
 */
export function getURISegment (index, location)
{
  // skip first URI slash
  index++;

  var urlParts = location.pathname.split("/");
  if (typeof urlParts[index] !== "undefined")
    return urlParts[index];

  return null;
}

/**
 * Returns value of GET parameter
 *
 * @param {String} name - name of the GET parameter
 * @returns String
 */
export function getQueryStringParameter (name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Returns value of GET parameter in SSR
 *
 * @param {String} name - name of the GET parameter
 * @param {Object} location - Gatsby location object
 * @returns String
 */
 export function getQueryStringParameterSSR (name, location) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Returns Post URL
 * @param {Object} post - post data
 * @returns String
 */
export function postURL( uid, metadata_id_from_previous_repository, lang ){
  let URL = (lang !== 'en-us') ? '/' + lang : '';
  URL += '/blog/' + uid + '/';
  URL += ( metadata_id_from_previous_repository ) ? metadata_id_from_previous_repository + '/' : '';
  return URL;
}

/**
 * Returns Blog Category Page URL
 * @param {String} category_name - category name
 * @returns String
 */
export function categoryURL( category_name, lang ){
  let URL = (lang !== 'en-us') ? '/' + lang : '';
  URL += ( category_name === 'Latest' ) ? '/blog/' : '/blog/' + category_name.replace(' ', '-').toLowerCase() + '/';
  return URL;
}
export function categoryURLClear( category_name ){
  let URL = ( category_name === 'Latest' ) ? '/blog/' : '/blog/' + category_name.replace(' ', '-').toLowerCase() + '/';
  return URL;
}

/**
 * Returns Blog Category name
 * @param {String or Object} category_name
 */
 export function categoryNameResolver( category_name, blogData ){
  if (typeof category_name === 'string') {
    if (category_name === 'Education')
      return blogData.categories_education_name;
    else if (category_name === 'News')
      return blogData.categories_news_name;
    else if (category_name === 'Stories')
      return blogData.categories_stories_name;
    else if (category_name === 'Latest')
      return blogData.categories_latest_name;
  } else if (typeof category_name === 'object') {
    let categories = '';
    for (let i in category_name) {
      if (i > 0) categories += ', ';
      categories += categoryNameResolver(category_name[i], blogData);
    }
    return categories;
  }
}

/**
 * Returns Blog Category description
 * @param {String or Object} category_name
 */
 export function categoryDescriptionResolver( category_name, blogData ){
  if (category_name === 'Education')
    return blogData.categories_education_description;
  else if (category_name === 'News')
    return blogData.categories_news_description;
  else if (category_name === 'Stories')
    return blogData.categories_stories_description;
  else if (category_name === 'Latest')
    return blogData.categories_latest_description;
}

/**
 * Returns correct Youtube's embedding link
 * @param {String} link
 */
 export function videoLinkResolver( link ){
  if (link.includes('youtu.be/'))
    return 'https://www.youtube.com/watch?v=' + link.slice(link.indexOf('youtu.be/') + 'youtu.be/'.length, link.length);
  else
    return link;
}

/**
 * Returns formatted date
 * @param {String} date
 */
export function textDate(date, lang) {
  if (!lang) lang = 'en-us';

  return new Date(date).toLocaleDateString(
    lang,
    { year: 'numeric', month: 'long', day: 'numeric' }
  );
}
