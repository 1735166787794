import $ from 'jquery'

export function GalleriesOverview() {
  if (window.intervalIds) {
    window.intervalIds.forEach(clearInterval);
  }
  window.intervalIds = [];

  if (window.timeoutIds) {
    window.timeoutIds.forEach(clearTimeout);
  }
  window.timeoutIds = [];

  let pageAnimationTimeout,
    heroAnimationTimeout,
    heroSliderTimeout,
    sliderInterval,
    gradientCurrentTimeout,
    desktopCurrentTimeout,
    mobileGeneralTimeout,
    mobileCurrentTimeout,
    scrollTimerTimeout,
    is_iPad;

  pageAnimationTimeout = setTimeout(function () {
    pageAnimationTimeout = null;

    // Detect the iPad
    if (
      (
        // iPad Pro:
        window.navigator.maxTouchPoints &&
        window.navigator.maxTouchPoints > 2 &&
        window.navigator.userAgent.includes('Macintosh')
      )
      ||
      (
        // iPad prior to iPad Pro / iOS 13:
        window.navigator.userAgent.includes('iPad')
      )
    )
      is_iPad = true;
    else
      is_iPad = false;

    var orientation = (document.documentElement.clientWidth < document.documentElement.clientHeight) ? 'portrait' : 'landscape';

    // Periods removing on desktop
    $('.galleries__hero__footer__text').each(function () {

      var
        $e = $(this),
        html = $(this).html();

      $e.html(html.replace(/\./g, '<span class="galleries__hero__footer__text__period">.</span>'));

    });


    // Hero animation
    heroAnimationTimeout = setTimeout(function () {
      heroAnimationTimeout = null;

      $('.galleries__hero').addClass('-animated');


      // Hero Slider
      $('.galleries__slider__desktop__item:first').addClass('-show');
      heroSliderTimeout = setTimeout(function () {
        heroSliderTimeout = null;
        $('.galleries__slider__mobile__item:first').addClass('-show');
      }, 200);
      window.timeoutIds.push(heroSliderTimeout);
      $('.galleries__hero__gradient:first').addClass('-show');

      var
        $desktop_next,
        $mobile_next,
        $gradient_next,
        $desktop_current,
        $mobile_current,
        $gradient_current;

      sliderInterval = setInterval(function () {

        $desktop_current = $('.galleries__slider__desktop__item.-show');
        $mobile_current = $('.galleries__slider__mobile__item.-show');
        $gradient_current = $('.galleries__hero__gradient.-show');

        $desktop_next =
          ($desktop_current.next('.galleries__slider__desktop__item').length > 0)
            ? $desktop_current.next('.galleries__slider__desktop__item')
            : $('.galleries__slider__desktop__item:first');

        $mobile_next =
          ($mobile_current.next('.galleries__slider__mobile__item').length > 0)
            ? $mobile_current.next('.galleries__slider__mobile__item')
            : $('.galleries__slider__mobile__item:first');

        $gradient_next =
          ($gradient_current.next('.galleries__hero__gradient').length > 0)
            ? $gradient_current.next('.galleries__hero__gradient')
            : $('.galleries__hero__gradient:first');

        $gradient_next.addClass('-show');
        $gradient_current.addClass('-hide');
        gradientCurrentTimeout = setTimeout(function () {
          gradientCurrentTimeout = null;
          $gradient_current.removeClass('-show');
          $gradient_current.removeClass('-hide');
        }, 400);
        window.timeoutIds.push(gradientCurrentTimeout);

        $desktop_next.addClass('-show');
        $desktop_current.addClass('-hide');
        desktopCurrentTimeout = setTimeout(function () {
          desktopCurrentTimeout = null;
          $desktop_current.removeClass('-show');
          $desktop_current.removeClass('-hide');
        }, 400);
        window.timeoutIds.push(desktopCurrentTimeout);

        mobileGeneralTimeout = setTimeout(function () {
          mobileGeneralTimeout = null;
          $mobile_next.addClass('-show');
          $mobile_current.addClass('-hide');
          mobileCurrentTimeout = setTimeout(function () {
            mobileCurrentTimeout = null;
            $mobile_current.removeClass('-show');
            $mobile_current.removeClass('-hide');
          }, 400);
          window.timeoutIds.push(mobileCurrentTimeout);
        }, 100);
        window.timeoutIds.push(mobileGeneralTimeout);

      }, 3500);
      window.intervalIds.push(sliderInterval);

    }, 600);
    window.timeoutIds.push(heroAnimationTimeout);

    // Full animation
    var
      is_mobile = document.documentElement.clientWidth < 768 || (document.documentElement.clientWidth <= 812 && document.documentElement.clientHeight < 400),
      scroll_top = 0,
      $full = (!is_mobile) ? $('.galleries__full__animation.-animated') : $('.galleries__full__animation.-mobile'),
      $container = $full.closest('.galleries__full'),
      $background = $full.find('.galleries__full__animation__background'),
      $foreground = $full.find('.galleries__full__animation__foreground'),
      $testimonials = $('.galleries__testimonials'),
      full_progress = 0,
      css_shift = 0,
      full_offset_top = $full.offset().top,
      full_height = $full.height(),
      testimonials_height = $testimonials.height(),
      testimonials_offset_top = $testimonials.offset().top,
      foreground_point = (!is_mobile) ? 100 : 40;

    var
      full_start = full_offset_top - full_height / 2 - 60,
      full_end = full_offset_top - 60 + 200,

      full_fix_start = full_offset_top - 60,
      full_fix_end = (!is_iPad) ? testimonials_offset_top - testimonials_height - 160 : testimonials_offset_top - testimonials_height - 90,

      testimonials_fix_start = testimonials_offset_top - testimonials_height - 160,
      testimonials_fix_end = testimonials_offset_top - testimonials_height + 140 - 20,

      testimonials_text_show = full_offset_top + testimonials_height,

      iPad_air_point = (document.documentElement.clientWidth === 768 && orientation === 'portrait') ? 20 : 0;

    if (is_iPad && orientation === 'landscape')
      full_fix_end = full_fix_end - 20;

    $(window).on('scroll.pxtrigger', function () {

      scroll_top = $(window).scrollTop();
      full_progress = ((scroll_top - (full_offset_top - full_height / 2 - 60)) / (full_height / 2)) * 100;
      full_progress = Math.floor(full_progress);

      // 1. UI section animation
      if (!is_mobile) {
        if (
          scroll_top >= full_start &&
          scroll_top <= full_end
        ) {
          css_shift = (100 - full_progress) / 3 - 0.34;
          $background.css({
            'margin-right': css_shift + '%',
            'margin-left': css_shift + '%'
          });
        }
      }

      // 2. Fix the UI section when scrolled to end of the section
      if (!is_mobile) {
        if (
          scroll_top >= full_fix_start &&
          scroll_top < full_fix_end - iPad_air_point
        ) {
          $background.css({
            'margin-right': 0,
            'margin-left': 0
          });
          $container.addClass('-fixed');
          $testimonials.removeClass('-relative');
        }
        else {
          $container.removeClass('-fixed');
          $testimonials.addClass('-relative');
        }
      }

      // 3. Show and hide the UI foreground image
      if (full_progress >= foreground_point)
        $foreground.addClass('-show');
      else {
        if (!is_mobile)
          $foreground.removeClass('-show');
      }

      // 4. Fix the Testimonials section when scrolled to end of the section
      if (!is_mobile) {
        if (
          scroll_top >= testimonials_fix_start - iPad_air_point &&
          scroll_top < testimonials_fix_end
        )
          $testimonials.addClass('-fixed');
        else
          $testimonials.removeClass('-fixed');
      }

      // 5. Show text in the Testimonials section
      if (!is_mobile) {
        if (scroll_top >= testimonials_text_show)
          $testimonials.addClass('-show-texts');
        else
          $testimonials.removeClass('-show-texts');
      }

      // 6. Unfix the Testimonials section
      if (!is_mobile) {
        if (scroll_top >= testimonials_fix_end)
          $testimonials.addClass('-scrolled');
        else
          $testimonials.removeClass('-scrolled');
      }

      // Undo animations if scroll_top < UI section offset top
      clearTimeout(scrollTimerTimeout);
      scrollTimerTimeout = setTimeout(function () {
        scrollTimerTimeout = null;

        full_progress = ((scroll_top - (full_offset_top - full_height / 2 - 60)) / (full_height / 2)) * 100;
        full_progress = Math.floor(full_progress);

        // 1. Hide the UI foreground image
        if (full_progress >= foreground_point)
          $foreground.addClass('-show');
        else
          $foreground.removeClass('-show');

        // 2. Undo the UI sections's animation
        if (!is_mobile) {
          if (scroll_top < full_start) {
            $background.css({
              'margin-right': 33 + '%',
              'margin-left': 33 + '%'
            });
          }
        }
      }, 250);
      window.timeoutIds.push(scrollTimerTimeout);

    });


    // iPad scrolling animation
    var
      $ipad_background = $('.galleries__scroll__animation__background'),
      $content = $('.galleries__scroll__animation__foreground__wrapper'),
      ipad_progress = 0;

    $(window).on('scroll.pxtrigger', function () {

      ipad_progress = ((scroll_top - ($ipad_background.offset().top - $(window).height())) / ($(window).height() + $ipad_background.height() - 60 - 120 + 140));

      if (
        scroll_top >= $ipad_background.offset().top - $(window).height() &&
        scroll_top <= $ipad_background.offset().top + $ipad_background.height() + 140
      ) {
        $content.css({
          transform: 'translate3d(0, -' + (($content.height() - $ipad_background.height()) * ipad_progress) + 'px, 0)'
        });
      }

    });


    // Footer elements animation
    var
      $footer = $('.galleries__footer__text'),
      footer_point = (!is_mobile) ? $footer.offset().top - $footer.height() / 2 - $(window).height() / 4 : $footer.offset().top - $footer.height() - 60;

    $(window).on('scroll.pxtrigger', function () {

      footer_point = (!is_mobile) ? $footer.offset().top - $footer.height() / 2 - $(window).height() / 4 : $footer.offset().top - $footer.height() - 60;

      if (scroll_top >= footer_point)
        $footer.addClass('-animated');

    });

    $('html').removeClass('-loading');

  }, 500);
  window.timeoutIds.push(pageAnimationTimeout);

  // Undo animations when going to the external link
  $('a[href^="http"]').not('[target="_blank"]').on('click.pxtrigger', function () {
    $('.galleries__hero').removeClass('-animated');
    $('.galleries__slider__desktop__item, .galleries__slider__mobile__item, .galleries__hero__gradient').removeClass('-show').removeClass('-hide');
  });
}
