/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import $ from 'jquery'
import * as Sentry from '@sentry/browser';
import { updateDocument } from './src/js/functions';
import { languageSwitcher } from './src/js/language-switcher'

import { RudderStack } from './src/js/rudderstack';

import { cloudProofing } from './src/js/cloud-proofing'
import { SmartAlbumsOverview } from './src/js/sa-overview'
import { SmartSlidesOverview } from './src/js/pss-overview'
import { ajaxFormsUpdateDocument } from './src/js/ajax_forms'
import { smartalbumsVideoTutorials } from './src/js/tutorials-smartalbums'
import { initSpoiler } from './src/js/spoiler'
import { initSmartAlbumsPricing } from './src/js/smartalbums-pricing'
import { loadProducts } from './src/js/pricing-load-products'
import { checkout } from './src/js/checkout'
import { initSmartSlidesPricing } from './src/js/smartslides-pricing'

import { about } from './src/js/about'
import { smartalbumsTrialDownloadSignupFollow } from './src/js/smartalbums-trial-download-signup-follow'
import { blog } from './src/js/blog'
import { post } from './src/js/post'
import { smartalbumsTry } from './src/js/smartalbums-try'
import { sammpsFeatures } from './src/js/sammps-features'
import { sammpsFeatures2 } from './src/js/sammps-features-2'
import { smartalbums2022 } from './src/js/smartalbums2022'
import { testimonials } from './src/js/testimonials'
import { resetGlobalBindings, waitForElement } from './src/js/_helpers'
import { setSentryCookie } from './src/js/set-sentry-cookie'
import { GalleriesOverview } from './src/js/psf-overview'
import { setUTMCookie } from './src/js/set-utm-cookie'
import { scrollSlideDown } from './src/js/scroll-slide-down'
import { HomePage } from './src/js/homepage'
import { CalculatorPage } from './src/js/calculator'
// import { BannerHandler } from './src/js/banner'
// import { Sale } from './src/js/sale'; // Sa 10-Year Sale
import { cssImagesLazyLoader } from './src/js/_css-images-lazy-loader';

// updateDocument is the equivalent of jQuery's document.ready
export const onRouteUpdate = ({
  location
}) => {
  function pagesScripts() {
    $('html, body').scrollTop(0);

    // Read the cookie
    function getCookie(name) {
      var matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    // Init the Sentry client if we have specific cookie
    if (getCookie('pws-run-sentry')) {
      Sentry.init({ dsn: window.px_config.Sentry_DSN });
    }

    setUTMCookie();

    // CSS images lazy loader
    cssImagesLazyLoader();

    languageSwitcher();

    resetGlobalBindings();

    updateDocument();
    ajaxFormsUpdateDocument();

    const currentLocation = location.pathname.replace(/\/$/, '');

    // BannerHandler();

    if (currentLocation.includes('/pricing')) {
      let firstLoad = true;

      if (firstLoad === true) {
        if (
          currentLocation.endsWith('/smartalbums/pricing') ||
          currentLocation.endsWith('/smartalbums/pricing/')
        ) {
          initSmartAlbumsPricing();
        } else if (
          currentLocation.endsWith('/galleries/pricing') ||
          currentLocation.endsWith('/galleries/pricing/')
        ) {
          testimonials();
        }

        initSpoiler();
        initSmartSlidesPricing();
        loadProducts();

        waitForElement('px-rpt').then((elm) => {
          checkout();
        });

        firstLoad = false;
      }
    } else if (
      currentLocation.endsWith('/smartalbums/cloud-proofing') ||
      currentLocation.endsWith('/smartalbums/cloud-proofing/')
    ) {
      cloudProofing();
    } else if (
      location.pathname.endsWith('/smartalbums')
      || location.pathname.endsWith('/smartalbums/')
    ) {
      SmartAlbumsOverview();
      testimonials();
    } else if (
      currentLocation.endsWith('/smartslides/examples') ||
      currentLocation.endsWith('/smartslides/examples/') ||
      currentLocation.endsWith('/galleries/examples') ||
      currentLocation.endsWith('/galleries/examples/')
    ) {
      scrollSlideDown();
    } else if (currentLocation.endsWith('/smartslides') || currentLocation.endsWith('/smartslides/')) {
      SmartSlidesOverview();
    } else if (currentLocation.includes('/tutorials-smartalbums')) {
      smartalbumsVideoTutorials();
    } else if (currentLocation.includes('/about')) {
      about();
    } else if (
      currentLocation.endsWith('/trial_download_sign_up_follow/windows') ||
      currentLocation.endsWith('/trial_download_sign_up_follow/windows/') ||
      currentLocation.endsWith('/trial_download_sign_up_follow/mac') ||
      currentLocation.endsWith('/trial_download_sign_up_follow/mac/')
    ) {
      smartalbumsTrialDownloadSignupFollow();
    }
    else if (location.pathname.includes('/blog')) {
      blog();
    }
    else if (
      currentLocation.endsWith('/smartalbums/try') ||
      currentLocation.endsWith('/smartalbums/try/')
    ) {
      smartalbumsTry();
    }
    else if (
      currentLocation.endsWith('/smartalbums/whatsnew') ||
      currentLocation.endsWith('/smartalbums/whatsnew/')
    ) {
      smartalbums2022();
      testimonials();
    } else if (
      location.pathname.endsWith('/galleries')
      || location.pathname.endsWith('/galleries/')
    ) {
      GalleriesOverview();
    }

    //SAMMPS Features page 1
    else if (currentLocation.includes('/sammps') && !currentLocation.includes('/sammps2') && !currentLocation.includes('/sammps-preview')) {
      sammpsFeatures();
    }

    //SAMMPS Features page 2
    else if (currentLocation.includes('/sammps2')) {
      sammpsFeatures2();
    }

    //SAMMPS Preview
    else if (currentLocation.includes('/sammps-preview')) {
      let isLoaded = setInterval(() => {
        if ($('html').hasClass('sammps-page')) {
          sammpsFeatures();
          clearInterval(isLoaded);
        }
      }, 100);
    }

    //Post Preview
    else if (currentLocation.includes('/post-preview')) {
      let isLoaded = setInterval(() => {
        if ($('#px-layout').hasClass('post-preview')) {
          if ($('.post__content.-not-processed').length > 0 && $('.post__banner.-not-processed').length > 0) {
            $('.post__content.-not-processed').html(
              $('.post__content.-not-processed').html().replace('{{banner}}', '<div data-id="post-banner"></div>')
            );

            $('.post__content.-not-processed [data-id="post-banner"]').after($('.post__banner.-not-processed'));

            $('.post__content.-not-processed').removeClass('-not-processed');
            $('.post__banner.-not-processed').removeClass('-not-processed');
          } else {
            $('.post__content.-not-processed').removeClass('-not-processed');
            $('.post__banner.-not-processed').removeClass('-not-processed');
          }

          post();

          clearInterval(isLoaded);
        }
      }, 100);
    }

    //Set cookie for the Sentry clieent
    else if (currentLocation.startsWith('/service/set-sentry-cookie')) {
      setSentryCookie();
    }

    //Homepage
    else if (currentLocation.length === 0 || currentLocation === '' || currentLocation === '/' || $('#px-layout').hasClass('main-page')) {
      HomePage();
      SmartSlidesOverview();
    }

    //Calculator page
    else if (currentLocation.includes('/calculator')) {
      CalculatorPage();
    }

    // Sa 10-Year Sale
    // else if ($('#px-layout').hasClass('sale')) {
    //   Sale();
    // }

    post();

    if (!$('html').hasClass('-show')) {
      setTimeout(() => {
        $('html').addClass('-show');
      }, 250);
    }

    RudderStack();

    $('html').addClass('-processed');
  }

  $(document).ready(function () {
    pagesScripts();
  });
}
