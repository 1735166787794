import $ from 'jquery'

export function languageSwitcher() {
  if ($('.language-switcher').length > 0) {
    if (!$('.language-switcher').hasClass('-processed')) {
      $('.language-switcher').addClass('-processed');

      let path = window.location.pathname;
      let $switcher = $('.language-switcher');
      let $trigger = $switcher.find('.language-switcher__trigger');
      let $items = $switcher.find('.language-switcher__popover').find('.language-switcher__item');

      if (path.includes('/blog')) {
        $switcher.find('.language-switcher__popover').find('.language-switcher__item[data-available-in-blog="false"]').hide();
        $('.mob-nav__navigation__item__content__lang-link[data-available-in-blog="false"]').hide();
      }

      if (path.includes('/videos')) {
        $switcher.find('.language-switcher__popover').find('.language-switcher__item[data-available-in-video="false"]').hide();
        $('.mob-nav__navigation__item__content__lang-link[data-available-in-video="false"]').hide();
      }

      $trigger.on('click.pxtrigger', function() {
        $switcher.toggleClass('-open');
      });

      $items.each(function() {
        let $e = $(this);

        $e.on('click.pxtrigger', function() {
          $items.removeAttr('aria-current');
          $e.attr('aria-current', 'page');

          $trigger.html($e.clone());

          setTimeout(() => {
            $switcher.removeClass('-open');
          }, 500);
        });
      });

      $(window).on('click.pxtrigger', function(event) {
        if ($(event.target).closest('.language-switcher').length === 0)
          $switcher.removeClass('-open');
      });

      $(window).on('keyup.pxtrigger', function(event) {
        if (event.key === 'Escape')
          $switcher.removeClass('-open');
      });
    }
  }
}
