import $ from 'jquery'
import fitvids from 'fitvids'
import { navigate } from "gatsby"
import Glide from '@glidejs/glide'
import { getQueryStringParameter } from "../helpers/link-helper";

export function blog() {
  let per_view;
  let gap;


  // GlideJS (carousel in Pixellu Presents)
  if ($('.glide').length > 0) {

    var resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);

    if ($(window).width() > 768) {
      per_view = 1.325;
      gap = 35;
    }
    else if ($(window).width() === 768) {
      per_view = 1.367;
      gap = 16;
    }
    else if ($(window).width() < 768) {
      per_view = 1;
      gap = 8;
    }

    var glide = new Glide('.glide', {
      type: 'slider',
      focusAt: 0,
      startAt: 0,
      perView: per_view,
      gap: gap
    }).mount();

    function arrows() {

      // Left arrow
      if (glide.index === 0)
        $('.glide__arrow--left').attr('disabled', 'disabled');
      else
        $('.glide__arrow--left').removeAttr('disabled');

      // Right arrow
      if (glide.index === $('.glide__slide').length - 1)
        $('.glide__arrow--right').attr('disabled', 'disabled');
      else
        $('.glide__arrow--right').removeAttr('disabled');

    }
    arrows();

    glide.on(['run.after'], function () {
      arrows();
    });

    $('.glide__arrow--right').on('click.pxtrigger', function () {
      arrows();
    });

    $('.glide__arrow--left').on('click.pxtrigger', function () {
      arrows();
    });

    // Open video in the Pixellu Presents section
    var $slider = $('.blog__presents__slider .glide');

    $('.blog__presents__slider__item__image').each(function () {

      $(this).on('click.pxtrigger', function () {

        var $target = $(this).parent().find('.video-target');

        if (!$slider.hasClass('glide--draggable') && $target.attr('draggable') === 'true')
          $target.magnificPopup('open');

      });

    });

  }


  // Fit videos to the screen
  fitvids('.post__content');


  // Category Mobile Navigation
  $('.blog__mobile-navigation').each(function () {

    var
      $menu = $(this),
      $current = $menu.find('.blog__mobile-navigation__current');

    $('.blog__mobile-navigation__current span').text($('.blog__mobile-navigation__dropdown a.active').text());

    $current.on('click.pxtrigger', function () {
      if ($menu.hasClass('-closed'))
        $menu.removeClass('-closed').addClass('-open');
      else if ($menu.hasClass('-open'))
        $menu.removeClass('-open').addClass('-closed');
    });

  });

  // Close Mobile SubNav ease on link click
  $('.blog__mobile-navigation__dropdown a').not('.active').each(function () {
    $(this).on('click.pxtrigger', function ($e) {
      $('.blog__mobile-navigation__current span').text($(this).text());
      var href = $(this).attr('href');
      $e.preventDefault();
      $('.blog__mobile-navigation').removeClass('-open').addClass('-closed');
      setTimeout(function () {
        navigate(href);
      }, 700);
    });
  });

  $('body').on('click.pxtrigger', function (event) {
    if ($(event.target).closest('.blog__mobile-navigation').length === 0)
      $('.blog__mobile-navigation').removeClass('-open').addClass('-closed');
  });

  $(document).on('keyup.pxtrigger', function (e) {
    if (e.keyCode === 27) {
      $('.blog__mobile-navigation').removeClass('-open');
      $('html').removeClass('-no-scroll');
    }
  });


  // Subscribe sections
  function subscribeSubmit(
    $form,
    action,
    $input,
    $button,
    $container
  ) {

    var reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Remove the "wrong" style of the input
    $input.on('keydown.pxtrigger', function () {
      $input.removeClass('-wrong');
    });

    // Wrong value
    if (reg_email.test($input.val()) === false)
      $input.addClass('-wrong').focus();

    // Sending
    else {

      $container.addClass('-loading');

      $.ajax({
        url: action,
        type: 'POST',
        data: $form.serialize(),
        dataType: 'json',
        success: function (response) {

          // Success
          if (response.success === true)
            $container.removeClass('-loading').addClass('-success');

          // Fields error
          else
            $container.removeClass('-loading').addClass('-error');

        },
        error: function (response) {

          // Backend error
          $container.removeClass('-loading').addClass('-error');

        }
      });

    }

  }

  $('.subscribe__form__main').each(function () {

    var
      $form = $(this),
      action = $form.attr('data-action'),
      $input = $form.find('.subscribe__form__main__input'),
      $button = $form.find('.subscribe__form__main__button'),
      $container = $form.closest('.subscribe');

    $form.on('submit.pxtrigger', function (e) {
      e.preventDefault();
    });

    $button.on('click.pxtrigger', function () {
      subscribeSubmit(
        $form,
        action,
        $input,
        $button,
        $container
      );
    });

    $input.on('keyup.pxtrigger', function (key) {
      if (key.keyCode === 13) {
        subscribeSubmit(
          $form,
          action,
          $input,
          $button,
          $container
        );
      }
    });

  });


  // Search handler
  if (getQueryStringParameter('q'))
    $('.internal-sub-header__nav__search__field').val(getQueryStringParameter('q')).addClass('-filled');

  $('.internal-sub-header__nav__search__field').each(function () {

    var
      $input = $(this),
      $search_form = $input.closest('form');

    $input.on('keyup.pxtrigger', function () {
      if ($input.val() === '')
        $input.removeClass('-filled');
      else
        $input.addClass('-filled');
    });

    $search_form.on('submit.pxtrigger', function (e) {

      if ($input.val() === '')
        e.preventDefault();

      $input.val($input.val().trim());

    });

  });


  // Search results page
  if (getQueryStringParameter('q')) {

    const search_query = getQueryStringParameter('q').toUpperCase();
    const search_query_array = search_query.split(' ');
    let replace_regex;
    let replace_mask;

    for (let s in search_query_array) {

      replace_regex = new RegExp(search_query_array[s], 'ig');
      replace_mask = '<span class="search__result__highlighted">' + search_query_array[s] + '</span>';

      // eslint-disable-next-line
      $('.search__result__content__title, .search__result__content__body').each(function () {

        // Unwrap links and other tags
        $(this).find('a').contents().unwrap();
        $(this).find('strong').contents().unwrap();
        $(this).find('b').contents().unwrap();
        $(this).find('i').contents().unwrap();

        $(this).find('h1, h2, h3, h4, h5, h6').replaceWith(function () {
          return $('<p />', { html: $(this).html() });
        });

        // Highlights the results
        if (
          $(this).text().toUpperCase().includes(search_query_array[s])
        ) {
          $(this).html($(this).html().replace(replace_regex, replace_mask));
        }

      });

    }

    // Remove images and embed contents
    $('.block-img, [data-oembed]').remove();

    // Mark all the paragraphs includes the match
    $('.search__result__content__body .search__result__highlighted').parent().addClass('-with-result');

    // Remove all the paragraphs that not includes the match
    $('.search__result__content__body > *').not('.-with-result').remove();

    // Remove all the not first paragraphs
    $('.search__result__content__body > *').not(':first-child').remove();

  }

  $('.search').addClass('-visible');

  let $banner;
  let resizeTimer;


  // One-tile Banner
  if ($('.one-tile-banner').length > 0 && !$('#px-layout').hasClass('preview-page')) {
    $banner = $('.one-tile-banner');

    if ($('.blog__category__latest__article[data-index="5"]').length > 0) {
      function setBannerPosition() {
        if ($(window).width() > 768)
          $('.blog__category__latest__article[data-index="1"]').after($banner);
        else if ($(window).width() === 768)
          $('.blog__category__latest__article[data-index="0"]').after($banner);
        else if ($(window).width() < 768)
          $('.blog__posts').append($banner);
      }

      setBannerPosition();

      // When resize stopped
      $(window).on('resize.pxtrigger', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          setBannerPosition();
        }, 250);
      });

      window.addEventListener('orientationchange.pxtrigger', function () {
        setTimeout(function () {
          setBannerPosition();
        }, 1000);
      });
    } else {
      $('.blog__category__latest__article:last').after($banner);
    }
  }


  // Two-tile Banner
  if ($('.two-tile-banner').length > 0 && !$('#px-layout').hasClass('preview-page')) {
    $banner = $('.two-tile-banner');

    if ($('.blog__category__latest__article[data-index="5"]').length > 0) {
      $banner.addClass('-visible');

      var
        $top = $('.blog__category__latest__article.-top'),
        $bottom = $('.blog__category__latest__article.-bottom');

      function setBannerHeight() {
        $('.blog__category__latest__article[data-index="3"]').after($top);
        if ($(window).width() > 768)
          $('.blog__category__latest__article[data-index="5"]').after($bottom);
        else if ($(window).width() === 768)
          $('.blog__category__latest__article[data-index="4"]').after($bottom);

        if ($(window).width() >= 768) {
          setTimeout(() => {
            $banner.css({
              height: ($bottom.offset().top + $bottom.height()) - $banner.offset().top
            });
          }, 500);
        } else {
          $banner.css({
            height: 'auto'
          });
          $('.blog__category__latest__article:last-child').after($top);
          if ($('.one-tile-banner').length > 0) {
            $top.after($('.one-tile-banner'));
          }
        }
      }

      setBannerHeight();

      // When resize stopped
      $(window).on('resize.pxtrigger', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          setBannerHeight();
        }, 250);
      });

      window.addEventListener('orientationchange.pxtrigger', function () {
        setTimeout(function () {
          setBannerHeight();
        }, 1000);
      });
    }
  }

  // Two-tiles Banner subscribe form
  function subscribeSubmitTwoTiles(
    $form,
    action,
    $input,
    $button,
    $container
  ) {

    var reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Remove the "wrong" style of the input
    $input.on('keydown.pxtrigger', function () {
      $input.removeClass('-wrong');
    });

    // Wrong value
    if (reg_email.test($input.val()) === false)
      $input.addClass('-wrong').focus();

    // Sending
    else {

      $container.addClass('-loading');

      $.ajax({
        url: action,
        type: 'POST',
        data: $form.serialize(),
        dataType: 'json',
        success: function (response) {

          // Success
          if (response.success === true) {
            $container.removeClass('-loading').addClass('-success');

            window.dataLayer.push({
              'event': 'tt-cta-banner-form-sent'
            });
          }

          // Fields error
          else
            $container.removeClass('-loading').addClass('-error');

        },
        error: function (response) {

          // Backend error
          $container.removeClass('-loading').addClass('-error');

        }
      });

    }

  }

  $('.two-tile-banner__subscribe__form__main').each(function () {

    var
      $form = $(this),
      action = $form.attr('data-action'),
      $input = $form.find('.two-tile-banner__subscribe__form__main__input'),
      $button = $form.find('.two-tile-banner__subscribe__form__main__button'),
      $container = $form.closest('.two-tile-banner__subscribe');

    $form.on('submit.pxtrigger', function (e) {
      e.preventDefault();
    });

    $button.on('click.pxtrigger', function () {
      subscribeSubmitTwoTiles(
        $form,
        action,
        $input,
        $button,
        $container
      );
    });

    $input.on('keyup.pxtrigger', function (key) {
      if (key.keyCode === 13) {
        subscribeSubmitTwoTiles(
          $form,
          action,
          $input,
          $button,
          $container
        );
      }
    });

  });


}
