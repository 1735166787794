import $ from 'jquery'

/**
 * Reset custom bindings to triggers on global elements
 */
export function resetGlobalBindings() {
  $(window).off('scroll.pxtrigger')
  $(window).off('resize.pxtrigger')

  $(document).off('keyup.pxtrigger')
  $(document).off('click.pxtrigger')
  $(document).off('scroll.pxtrigger')
  $(document).off('keydown.pxtrigger')

  $('body').off('click.pxtrigger')

  $('[data-action="yearly"], [data-action="monthly"], [data-action="switch"]').off('click.pxtrigger');
}

export function waitForElement(selector) {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              observer.disconnect();
              resolve(document.querySelector(selector));
          }
      });

      // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}
